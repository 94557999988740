import React from 'react';
import ReactDOM from 'react-dom';
import asDrawer from './drawer';
import DisclaimerModal from '../../../../../../loan_calculator/static/loan_calculator/js/components/loan_calculator/disclaimer-modal'



window.ReactDOM = ReactDOM

class PensionDisclaimer extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (<div className="">
      <div className="legal-disclaimer-small">Útreikningar miðast við ofangreindar forsendur. ATH þetta er eingöngu til viðmiðunar og ekki er um endanlegan útreikning að ræða.</div>
      <DisclaimerModal legalDisclaimer={this.props.legalDisclaimer} modalId="pensionDisclaimerModal"/>
      <div className="legal-disclaimer"><a className={this.props.drawer} href="#" data-toggle="modal" data-target="#pensionDisclaimerModal" >Fyrirvari</a></div>
    </div>);
  }
}

export default asDrawer(PensionDisclaimer);
