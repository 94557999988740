import $ from 'jquery'

export const loan = (value, percentage) => {
  return parseInt((parseFloat(value) * (parseFloat(percentage) / 100)));
}

export const maxLoan = (amount, percentage) => {
  return parseInt((parseFloat(amount) * (percentage / 100)))
}

export const monthlyTooltipLabel = (tooltipItems, data) => {
  return `${data.datasets[tooltipItems.datasetIndex].label}: ${formatISK(tooltipItems.yLabel)} kr. á mánuði`;
}

export const titleCallback = (tooltipItems, data) => {
  // Pick first xLabel for now
  var title = '';
  var labels = data.labels;
  var labelCount = labels ? labels.length : 0;

  if (tooltipItems.length > 0) {
    var item = tooltipItems[0];

    if (item.xLabel) {
      title = item.xLabel;
    } else if (labelCount > 0 && item.index < labelCount) {
      title = labels[item.index];
    }
  }

  return `${title} ára`;
}

export const piePercentageTotalCallback = (tooltipItem, data) => {
  var allData = data.datasets[tooltipItem.datasetIndex].data;
  var tooltipLabel = data.labels[tooltipItem.index];
  var tooltipData = allData[tooltipItem.index];
  var total = 0;
  for (var i in allData) {
    total += allData[i];
  }
  var tooltipPercentage = Math.round((tooltipData / total) * 1000);
  return tooltipLabel + ': ' + formatISK(tooltipData) + ' kr. (' + formatPercentage(tooltipPercentage, 1) + '%)';
}

export const toMoney = (n, decimals, decimal_sep, thousands_sep) => {
  let c = isNaN(decimals) ? 2 : Math.abs(decimals); //if decimal is zero we must take it, it means user does not want to show any decimal
  let d = decimal_sep || '.'; //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

  /*
    according to [http://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
    the fastest way to check for not defined parameter is to use typeof value === 'undefined'
    rather than doing value === undefined.
  */
  let t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep; //if you don't want to use a thousands separator you can pass empty string as thousands_sep value

  let sign = (n < 0) ? '-' : '';

  //extracting the absolute value of the integer part of the number and converting to string
  let i = parseInt(n = Math.abs(n).toFixed(c), 10) + '';

  let j = ((j = i.length) > 3) ? j % 3 : 0;
  return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
}

export const formatISK = (d) => {
  return toMoney(d, 0, ',', '.');
}

export const formatPercentage = (d, precision = 2) => {
  return toMoney(d / 10, precision, ',', '.');
}

export const filterParams = (terms) => {
  let blacklist = [
    "url",
    "showResults",
  ]

  let toReturn = {}
  for (let k of Object.keys(terms)) {
    if (blacklist.indexOf(k) < 0) {
      toReturn[k] = terms[k]
    }
  }

  return toReturn
}

export const buildParams = (_terms) => {
  let terms = filterParams(_terms)

  let percentages = [
    "baseEmployerRate",
    "baseEmployeeRate",
    "baseReturnRate",
    "extraEmployeeRate",
    "extraEmployerRate",
    "extraReturnRate",
    "specialEmployeeRate",
    "specialEmployerRate",
    "specialReturnRate",
  ]

  let toReturn = {}
  for (let k of Object.keys(terms)) {
    if (percentages.indexOf(k) !== -1) {
      toReturn[k] = terms[k] / 1000.0
    } else {
      toReturn[k] = terms[k]
    }
  }

  toReturn["specialEmployerRate"] = toReturn["specialSliderValue"] / 1000.0
  toReturn["baseEmployerRate"] = toReturn["baseSliderValue"] / 1000.0 - toReturn["baseEmployeeRate"]

  return toReturn
}

export const deparam = (params, coerce) => {
  var obj = {},
    coerce_types = { 'true': !0, 'false': !1, 'null': null };

  // Iterate over all name=value pairs.
  $.each(params.replace(/\+/g, ' ').split('&'), function (j, v) {
    var param = v.split('='),
      key = decodeURIComponent(param[0]),
      val,
      cur = obj,
      i = 0,

      // If key is more complex than 'foo', like 'a[]' or 'a[b][c]', split it
      // into its component parts.
      keys = key.split(']['),
      keys_last = keys.length - 1;

    // If the first keys part contains [ and the last ends with ], then []
    // are correctly balanced.
    if (/\[/.test(keys[0]) && /\]$/.test(keys[keys_last])) {
      // Remove the trailing ] from the last keys part.
      keys[keys_last] = keys[keys_last].replace(/\]$/, '');

      // Split first keys part into two parts on the [ and add them back onto
      // the beginning of the keys array.
      keys = keys.shift().split('[').concat(keys);

      keys_last = keys.length - 1;
    } else {
      // Basic 'foo' style key.
      keys_last = 0;
    }

    // Are we dealing with a name=value pair, or just a name?
    if (param.length === 2) {
      val = decodeURIComponent(param[1]);

      // Coerce values.
      if (coerce) {
        val = val && !isNaN(val) ? +val              // number
          : val === 'undefined' ? undefined         // undefined
            : coerce_types[val] !== undefined ? coerce_types[val] // true, false, null
              : val;                                                // string
      }

      if (keys_last) {
        // Complex key, build deep object structure based on a few rules:
        // * The 'cur' pointer starts at the object top-level.
        // * [] = array push (n is set to array length), [n] = array if n is 
        //   numeric, otherwise object.
        // * If at the last keys part, set the value.
        // * For each keys part, if the current level is undefined create an
        //   object or array based on the type of the next keys part.
        // * Move the 'cur' pointer to the next level.
        // * Rinse & repeat.
        for (; i <= keys_last; i++) {
          key = keys[i] === '' ? cur.length : keys[i];
          cur = cur[key] = i < keys_last
            ? cur[key] || (keys[i + 1] && isNaN(keys[i + 1]) ? {} : [])
            : val;
        }

      } else {
        // Simple key, even simpler rules, since only scalars and shallow
        // arrays are allowed.

        if ($.isArray(obj[key])) {
          // val is already an array, so push on the next value.
          obj[key].push(val);

        } else if (obj[key] !== undefined) {
          // val isn't an array, but since a second value has been specified,
          // convert val into an array.
          obj[key] = [obj[key], val];

        } else {
          // val is a scalar.
          obj[key] = val;
        }
      }

    } else if (key) {
      // No value was defined, so set something meaningful.
      obj[key] = coerce
        ? undefined
        : '';
    }
  });

  return obj;
}

export const fractionalSkipRatio = (chart) => {
  if (chart.config.type == "bar" || chart.config.type == "line") {
    var xScale = chart.scales['x-axis-0'];
    if (xScale.options.ticks.maxTicksLimit) {
      // store the original maxTicksLimit
      xScale.options.ticks._maxTicksLimit = xScale.options.ticks.maxTicksLimit;
      // let chart.js draw the first and last label
      xScale.options.ticks.maxTicksLimit = (xScale.ticks.length % xScale.options.ticks._maxTicksLimit === 0) ? 1 : 2;

      var originalXScaleDraw = xScale.draw
      xScale.draw = function () {
        originalXScaleDraw.apply(this, arguments);

        var xScale = chart.scales['x-axis-0'];
        if (xScale.options.ticks.maxTicksLimit) {
          var helpers = Chart.helpers;

          var tickFontColor = helpers.getValueOrDefault(xScale.options.ticks.fontColor, Chart.defaults.global.defaultFontColor);
          var tickFontSize = helpers.getValueOrDefault(xScale.options.ticks.fontSize, Chart.defaults.global.defaultFontSize);
          var tickFontStyle = helpers.getValueOrDefault(xScale.options.ticks.fontStyle, Chart.defaults.global.defaultFontStyle);
          var tickFontFamily = helpers.getValueOrDefault(xScale.options.ticks.fontFamily, Chart.defaults.global.defaultFontFamily);
          var tickLabelFont = helpers.fontString(tickFontSize, tickFontStyle, tickFontFamily);
          var tl = xScale.options.gridLines.tickMarkLength;

          var isRotated = xScale.labelRotation !== 0;
          var yTickStart = xScale.top;
          var yTickEnd = xScale.top + tl;
          var chartArea = chart.chartArea;

          // use the saved ticks
          var maxTicks = xScale.options.ticks._maxTicksLimit - 1;
          var ticksPerVisibleTick = xScale.ticks.length / maxTicks;

          // chart.js uses an integral skipRatio - this causes all the fractional ticks to be accounted for between the last 2 labels
          // we use a fractional skipRatio
          var ticksCovered = 0;
          helpers.each(xScale.ticks, function (label, index) {
            if (index < ticksCovered)
              return;

            ticksCovered += ticksPerVisibleTick;

            // chart.js has already drawn these 2
            if (index === 0 || index === (xScale.ticks.length - 1))
              return;

            // copy of chart.js code
            var xLineValue = this.getPixelForTick(index);
            var xLabelValue = this.getPixelForTick(index, this.options.gridLines.offsetGridLines);

            if (this.options.gridLines.display) {
              this.ctx.lineWidth = this.options.gridLines.lineWidth;
              this.ctx.strokeStyle = this.options.gridLines.color;

              xLineValue += helpers.aliasPixel(this.ctx.lineWidth);

              // Draw the label area
              this.ctx.beginPath();

              if (this.options.gridLines.drawTicks) {
                this.ctx.moveTo(xLineValue, yTickStart);
                this.ctx.lineTo(xLineValue, yTickEnd);
              }

              // Draw the chart area
              if (this.options.gridLines.drawOnChartArea) {
                this.ctx.moveTo(xLineValue, chartArea.top);
                this.ctx.lineTo(xLineValue, chartArea.bottom);
              }

              // Need to stroke in the loop because we are potentially changing line widths & colours
              this.ctx.stroke();
            }

            if (this.options.ticks.display) {
              this.ctx.save();
              this.ctx.translate(xLabelValue + this.options.ticks.labelOffset, (isRotated) ? this.top + 12 : this.options.position === "top" ? this.bottom - tl : this.top + tl);
              this.ctx.rotate(helpers.toRadians(this.labelRotation) * -1);
              this.ctx.font = tickLabelFont;
              this.ctx.textAlign = (isRotated) ? "right" : "center";
              this.ctx.textBaseline = (isRotated) ? "middle" : this.options.position === "top" ? "bottom" : "top";
              this.ctx.fillText(label, 0, 0);
              this.ctx.restore();
            }
          }, xScale);
        }
      };
    }
  }
}


export const findGetParameter = (parameterName) => {
  var result = null,
      tmp = [];
  var items = location.search.substr(1).split("&");
  for (var index = 0; index < items.length; index++) {
      tmp = items[index].split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}