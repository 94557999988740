import React from 'react';
import ReactDOM from 'react-dom';
import asDrawer from './drawer';
import { formatISK, formatPercentage, buildParams, findGetParameter } from './utils';
import { setState, fetchPension } from '../../redux/actions';
import Cleave from 'cleave.js/react';
import $ from 'jquery'

import RangeSlider from 'react-bootstrap-range-slider';

window.ReactDOM = ReactDOM

class PensionCalculatorBaseSavings extends React.Component {

  constructor(props) {
    super(props);
    this.baseSliderChange = this.baseSliderChange.bind(this);

  }

  // componentDidUpdate () {
  //   $('[data-toggle="tooltip"]').tooltip();
  // }


  onInputChange(key, event) {
    var val = event.target.value.replace(/\./g, '');
    if (val === "") { val = 0 }
    Promise.resolve(this.props.dispatch(setState({ key: key, value: parseInt(val) })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }

  calculateMonthlyPayment() {
    return (this.props.terms.baseEmployeeRate + this.props.terms.baseEmployerRate) * this.props.monthlyPay
  }

  calculateRangeHighlights() {
    return [
      { "start": (this.props.terms.baseEmployerRate + this.props.terms.baseEmployeeRate), "end": (this.props.terms.baseEmployerRate + this.props.terms.baseEmployeeRate + this.props.terms.specialEmployerRate) }
    ];
  }

  baseSliderChange(event) {
    let val = event.target.value
    if (val < (this.props.terms.baseEmployerRate + this.props.terms.baseEmployeeRate)) {
      val = (this.props.terms.baseEmployerRate + this.props.terms.baseEmployeeRate)
    }
    let specialSlider = this.props.terms.baseEmployeeRate + this.props.terms.baseEmployerRate + this.props.terms.specialEmployerRate - val
    Promise.resolve(this.props.dispatch(setState({ key: 'baseSliderValue', value: val })))
      .then(() => {
        Promise.resolve(this.props.dispatch(setState({ key: 'specialSliderValue', value: specialSlider })))
          .then(() => {
            this.props.dispatch(fetchPension(buildParams(this.props.terms)))
          })
      })

  }

  sliderChange(key, event) {
    Promise.resolve(this.props.dispatch(setState({ key: key, value: parseInt(event.target.value) })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }

  render() {

    return (<div className={`col-xs-12 col-md-4 base-savings drawer-${this.props.drawer}`}>
      <h3>Skyldusparnaður</h3>

      <label className="simpleLabel" htmlFor="currentRights">Núverandi réttindi <span className="info-bubble" data-toggle="tooltip" data-placement="top" title="krónur á mánuði miðað við 67 ára">
        <i className="fa fa-info" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd"><circle cx="8" cy="8" r="8" fill="#E5AF23" /><path fill="#252525" fillRule="nonzero" d="M7.175 6.5V12h1.65V6.5h-1.65zm-.088-1.573c0-.55.352-.891.913-.891.55 0 .913.341.913.891 0 .528-.363.88-.913.88-.561 0-.913-.352-.913-.88z" /></g>
          </svg>
        </i>
      </span>
      </label>
      <Cleave placeholder="Núverandi réttindi"
        id="currentRights"
        options={{
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        }}
        value={this.props.terms.baseCurrentValue}
        onBlur={this.onInputChange.bind(this, 'baseCurrentValue')} />

      <div className="form-group mb-5">
        <h4>Heildarframlag
          <span className="info-bubble" data-toggle="tooltip" data-placement="top" title="Allt að 14% frá 1. júlí 2017 en 15,5% frá 1. júlí 2018">
            <i className="fa fa-info" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <g fill="none" fillRule="evenodd"><circle cx="8" cy="8" r="8" fill="#E5AF23" /><path fill="#252525" fillRule="nonzero" d="M7.175 6.5V12h1.65V6.5h-1.65zm-.088-1.573c0-.55.352-.891.913-.891.55 0 .913.341.913.891 0 .528-.363.88-.913.88-.561 0-.913-.352-.913-.88z" /></g>
              </svg>
            </i>
          </span>
        </h4>
        <div className="contribution-container">
          <div className="contrubution-p-container">
            <p className="contribution">Samtrygging: </p>
            <p className="second-p"> {`${formatPercentage(this.props.terms.baseSliderValue, 1)}%`}</p>
          </div>
          <div className="contrubution-p-container">
            <p className="contribution">Tilgreind séreign:  </p>
            <p className="second-p">{`${formatPercentage(this.props.terms.baseEmployeeRate + this.props.terms.baseEmployerRate + this.props.terms.specialEmployerRate - this.props.terms.baseSliderValue, 1)}%`}</p>
          </div>
        </div>


        <RangeSlider
          id="baseSlider"
          value={this.props.terms.baseSliderValue}
          step={5}
          onChange={this.baseSliderChange}

          max={(this.props.terms.baseEmployerRate + this.props.terms.baseEmployeeRate + this.props.terms.specialEmployerRate)}
          min={0}
          tooltipLabel={function (val) { return `${val / 10.0}%` }}
        />
      </div>

      <div className="form-group single-color-slider">
        <h4>Aldur við byrjun úttektar</h4>
        <RangeSlider
          id="baseAgeOutSlider"
          value={this.props.terms.baseAgeOut}
          onChange={this.sliderChange.bind(this, 'baseAgeOut')}
          step={1}
          min={60}
          max={80}
        />
      </div>

      <div className="form-group single-color-slider">
        <h4 className="base-savings-last-h4">Vænt ávöxtun</h4>
        <p>{formatPercentage(this.props.terms.baseReturnRate, 1)} %</p>
      </div>


    </div>);
  }
}

export default asDrawer(PensionCalculatorBaseSavings);


