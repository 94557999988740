import React from 'react'
import ReactDOM from 'react-dom'
import asDrawer from './drawer'
import 'chart.js/auto';
import { Bar, Line, Pie, defaults } from 'react-chartjs-2'
import { formatISK, formatPercentage, fractionalSkipRatio, monthlyTooltipLabel, titleCallback, piePercentageTotalCallback } from './utils'
import { yellow, yellow50, green, green50, grey, grey50, darkGrey, darkGrey50 } from './colors'

import PensionDisclaimer from './pension-disclaimer'
import PensionCopyLink from './pension-copy-link'


// Chart.pluginService.register({
//   afterUpdate: fractionalSkipRatio,
// });
// Chart.defaults.global.legend.position = 'bottom';


window.ReactDOM = ReactDOM

class PensionCalculatorResults extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    if (this.props.drawer === 'open' && [this.props.terms.baseSavingsChecked, this.props.terms.extraSavingsChecked, this.props.terms.specialSavingsChecked].some(x => x)) {

      let start = Math.min(this.props.terms.baseAgeOut, this.props.terms.extraAgeOut, this.props.terms.specialAgeOut)

      let baseStart = this.props.terms.baseAgeOut - start
      let extraStart = this.props.terms.extraAgeOut - start
      let specialStart = this.props.terms.specialAgeOut - start

      //let start = parseInt(this.props.terms.baseAgeOut)
      let end = Math.max(
        90,
        this.props.terms.extraAgeOut + this.props.terms.extraPayoutYears,
        this.props.terms.specialAgeOut + this.props.terms.specialPayoutYears
      )

      let range = (start, end) => Array.from({ length: (end - start) }, (v, k) => k + start);
      let labels = range(start, end)

      let baseData = new Array(end - start).fill(0);
      for (let i = baseStart; i < end; i++) {
        baseData[i] = this.props.pension.data.regular.expected_monthly_pension
      }


      let extraData = new Array(end - start).fill(0);
      for (let i = extraStart; i < this.props.terms.extraPayoutYears + extraStart; i++) {
        extraData[i] = this.props.pension.data.extra.expected_monthly_payment
      }

      let specialData = new Array(end - start).fill(0);
      for (let i = specialStart; i < this.props.terms.specialPayoutYears + specialStart; i++) {
        specialData[i] = this.props.pension.data.special.expected_monthly_payment
      }

      let datasets = []

      if (this.props.terms.baseSavingsChecked) {
        datasets.push({
          label: 'Samtrygging',
          backgroundColor: yellow50,
          data: baseData,
        })
      }

      if (this.props.terms.specialSavingsChecked) {
        datasets.push({
          label: 'Tilgreind séreign',
          backgroundColor: darkGrey50,
          data: specialData,
        })
      }

      if (this.props.terms.extraSavingsChecked) {
        datasets.push({
          label: 'Viðbótarlífeyrir',
          backgroundColor: green50,
          data: extraData,
        })
      }


      let asDatasets = [
        {
          label: 'Framlag launþega',
          backgroundColor: yellow,
          data: this.props.pension.data.extra.payments.employee,
          borderWidth: 2,
          pointRadius: 0,
          fill: 'origin',
        },
        {
          label: 'Framlag launagreiðanda',
          backgroundColor: green,
          data: this.props.pension.data.extra.payments.employer,
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          label: 'Ávöxtun',
          backgroundColor: grey,
          data: this.props.pension.data.extra.payments.interests,
          borderWidth: 2,
          pointRadius: 0,
        },
      ]

      let asLabels = this.props.pension.data.extra.payments.years

      let pieData = [this.props.pension.data.extra.payments.employee.slice(-1)[0], this.props.pension.data.extra.payments.employer.slice(-1)[0], this.props.pension.data.extra.payments.interests.slice(-1)[0]]
      let pieTotal = this.props.pension.data.extra.payments.employee.slice(-1) + this.props.pension.data.extra.payments.employer.slice(-1) + this.props.pension.data.extra.payments.interests.slice(-1)



      let specialDatasets = [
        {
          label: 'Framlag launagreiðanda',
          backgroundColor: yellow,
          data: this.props.pension.data.special.payments.employer,
          borderWidth: 2,
          pointRadius: 0,
          fill: 'origin',
        },
        {
          label: 'Ávöxtun',
          backgroundColor: grey,
          data: this.props.pension.data.special.payments.interests,
          borderWidth: 2,
          pointRadius: 0,
        },
      ]

      let specialLabels = this.props.pension.data.special.payments.years

      let specialPieData = [this.props.pension.data.special.payments.employer.slice(-1)[0], this.props.pension.data.special.payments.interests.slice(-1)[0]]
      let specialPieTotal = this.props.pension.data.special.payments.employer.slice(-1) + this.props.pension.data.special.payments.interests.slice(-1)



      return (<div className={`results drawer-${this.props.drawer} ${this.props.pension.isFetching ? 'fetching' : ''}`}>
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-9 col-lg-8 results-col">
              <div className="col-xs-12">
                <h3 className="pull-left results-h3">Niðurstöður</h3>
                <PensionCopyLink {...this.props} drawer='open' />
              </div>

              <PensionDisclaimer {...this.props} drawer='closed' />

              <div className="col-xs-12 results-h4">
                <h4>Áætlaðar greiðslur <span>Allar upphæðir eru brúttó tölur (fyrir skatta)</span></h4>
              </div>

              <div className="col-xs-12" >

                <Bar height={50}
                  width={100}
                  data={{
                    labels: labels,
                    datasets: datasets
                  }}
                  options={{
                    animation: false,
                    maintainAspectRatio: true,
                    responsive: true,
                    tooltips: {
                      callbacks: {
                        label: monthlyTooltipLabel,
                        title: titleCallback
                      }
                    },
                    scales: {
                      x:
                      {
                        stacked: true,
                        gridLines: { display: false },
                      }
                      ,
                      y:
                      {
                        stacked: true,
                        ticks: {
                          min: 0,
                          callback: function (label, index, labels) {
                            return `${formatISK(label)} kr`;
                          }
                        }
                      }

                    }
                  }} />
              </div>

              <div className="col-xs-12">
                <h3 className="results-h3">Samtrygging</h3>
              </div>

              <div className="col-xs-12">

                <div className="d-flex w-100 table">
                  <div className="row results-table-row">
                    <div className="table-head">Mánaðarleg innborgun í samtryggingu</div>
                    <div className="text-right">{formatISK(this.props.pension.data.regular.monthly_payment)} kr.</div>
                  </div>
                  <div className="row results-table-row">
                    <div className="table-head">Mánaðarleg réttindi frá {this.props.terms.baseAgeOut} ára aldri ævilangt</div>
                    <div className="text-right">{formatISK(this.props.pension.data.regular.expected_monthly_pension)} kr.</div>
                  </div>
                </div>

              </div>




              {this.props.terms.specialSavingsChecked && this.props.terms.specialSliderValue > 0 &&
                <div>
                  <div className="col-xs-12 mt30">
                    <h3 className="results-h3">Tilgreind séreign</h3>
                  </div>

                  <div className="d-flex w-100 table">
                    <div className="row results-table-row">
                      <div className="table-head">Heildarinnborgun</div>
                      <div className="text-right">{formatISK(this.props.pension.data.special.total_payed)} kr.</div>
                    </div>
                    <div className="row results-table-row">
                      <div className="table-head">Mánaðarleg innborgun í tilgreinda séreign</div>
                      <div className="text-right">{formatISK(this.props.pension.data.special.monthly_payment)} kr.</div>
                    </div>
                    <div className="row results-table-row">
                      <div className="table-head">Heildarinneign við {this.props.terms.specialAgeOut} ára aldur</div>
                      <div className="text-right">{formatISK(this.props.pension.data.special.total_amount_with_interests)} kr.</div>
                    </div>
                    <div className="row results-table-row">
                      <div className="table-head">Mánaðarleg greiðsla frá {this.props.terms.specialAgeOut} ára aldri til {this.props.terms.specialAgeOut + this.props.terms.specialPayoutYears} ára aldurs</div>
                      <div className="text-right">{formatISK(this.props.pension.data.special.expected_monthly_payment)} kr.</div>
                    </div>
                  </div>

                  <div className="col-xs-12 h4" >
                    <h4>Vöxtur tilgreindrar séreignar</h4>
                  </div>

                  <div className="col-xs-12" >


                    <Line height={50}
                      width={100} 
                      data={{
                        labels: specialLabels,
                        datasets: specialDatasets,
                      }} options={{
                        animation: false,
                        maintainAspectRatio: true,
                        responsive: true,
                        tooltips: {
                          mode: 'index',
                          callbacks: {
                            label: monthlyTooltipLabel,
                            title: titleCallback,
                          }
                        },
                        scales: {
                          x:
                          {
                            stacked: true,
                            ticks: {
                              maxTicksLimit: specialLabels.length / 12 / 2,
                              callback: function (label, index, labels) {
                                return `${label.toFixed(0)}`;
                              }
                            },
                            gridLines: { display: false },
                          }
                          ,
                          y:
                          {
                            stacked: true,
                            ticks: {
                              min: 0,
                              callback: function (label, index, labels) {
                                return `${formatISK(label / 1000000)} m. kr.`;
                              }
                            }
                          }

                        }
                      }} />
                  </div>

                  <div className="col-xs-12"  >
                    <h4>Skipting tilgreindrar séreignar</h4>
                  </div>
                  <div className="col-8 mx-auto">
                    <Pie 
                    data={{
                      labels: ["Framlag launagreiðanda", "Ávöxtun"],
                      datasets: [{
                        backgroundColor: [
                          yellow,
                          grey,
                        ],
                        data: specialPieData
                      }],
                    }} options={{
                      responsive: true,
                      maintainAspectRatio: true,
                      tooltips: {
                        callbacks: {
                          label: piePercentageTotalCallback
                        }
                      }
                    }} />
                  </div>
                </div>
              }



              {this.props.terms.extraSavingsChecked &&
                <div>
                  <div className="d-flex w-100 table">
                    <div className="col-xs-12 mt30">
                      <h3 className="results-h3">Séreignarsparnaður</h3>
                    </div>
                    <div className="row results-table-row">
                      <div className="table-head">Heildarinnborgun</div>
                      <div className="text-right">{formatISK(this.props.pension.data.extra.total_payed)} kr.</div>
                    </div>
                    <div className="row results-table-row">
                      <div className="table-head">Mánaðarleg innborgun í séreignarsparnað</div>
                      <div className="text-right">{formatISK(this.props.pension.data.extra.monthly_payment)} kr.</div>
                    </div>
                    <div className="row results-table-row">
                      <div className="table-head">Heildarinneign við {this.props.terms.extraAgeOut} ára aldur</div>
                      <div className="text-right">{formatISK(this.props.pension.data.extra.total_amount_with_interests)} kr.</div>
                    </div>
                    <div className="row results-table-row">
                      <div className="table-head">Mánaðarleg greiðsla frá {this.props.terms.extraAgeOut} ára aldri til {this.props.terms.extraAgeOut + this.props.terms.extraPayoutYears} ára aldurs</div>
                      <div className="text-right">{formatISK(this.props.pension.data.extra.expected_monthly_payment)} kr.</div>
                    </div>
                  </div>

                  <div className="col-xs-12" >
                    <h4>Vöxtur séreignarsparnaðar</h4>
                  </div>

                  <div className="col-xs-12" >


                    <Line height={50}
                      width={100} 
                      data={{
                        labels: asLabels,
                        datasets: asDatasets,
                      }} options={{
                        animation: false,
                        maintainAspectRatio: true,
                        responsive: true,
                        tooltips: {
                          mode: 'index',
                          callbacks: {
                            label: monthlyTooltipLabel,
                            title: titleCallback,
                          }
                        },
                        scales: {
                          x:
                          {
                            stacked: true,
                            ticks: {
                              maxTicksLimit: asLabels.length / 12 / 2,
                              callback: function (label, index, labels) {
                                return `${label.toFixed(0)}`;
                              }
                            },
                            gridLines: { display: false },
                          }
                          ,
                          y:
                          {
                            stacked: true,
                            ticks: {
                              min: 0,
                              callback: function (label, index, labels) {
                                return `${formatISK(label / 1000000)} m. kr.`;
                              }
                            }
                          }

                        }
                      }} />
                  </div>

                  <div className="col-xs-12"  >
                    <h4>Skipting séreignarsparnaðar</h4>
                  </div>
                  <div className="col-8 mx-auto">
                    <Pie
                      data={{
                        labels: ["Framlag launþega", "Framlag launagreiðanda", "Ávöxtun"],
                        datasets: [{
                          backgroundColor: [
                            yellow,
                            green,
                            grey,
                          ],
                          data: pieData
                        }],
                      }} options={{
                        responsive: true,
                        tooltips: {
                          callbacks: {
                            label: piePercentageTotalCallback
                          }
                        }
                      }} />
                  </div>
                </div>
              }
            </div>

          </div>






        </div>


      </div>
      );
    } else {
      return (<div />)
    }
  }
}

export default asDrawer(PensionCalculatorResults);



