import React from 'react';
import ReactDOM from 'react-dom';
import asDrawer from './drawer';
import { formatISK, formatPercentage, buildParams } from './utils';
import { setState, fetchPension } from '../../redux/actions';
import Cleave from 'cleave.js/react';

import RangeSlider from 'react-bootstrap-range-slider';

window.ReactDOM = ReactDOM

class PensionCalculatorExtraSavings extends React.Component {

  constructor(props) {
    super(props);
    this.sliderChange = this.sliderChange.bind(this);
  }

  onInputChange(key, event) {
    var val = event.target.value.replace(/\./g, '');
    if (val === "") { val = 0 }
    if (key === "extraEmployerRate") {
      this.props.dispatch(setState({ key: "extraEmployerRate10", value: "" + val }))
      val = parseFloat(val.replace(/,/g, '.'))
      val = 10 * val
      this.toggleInput()
    }
    Promise.resolve(this.props.dispatch(setState({ key: key, value: parseInt(val) })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }

  sliderChange(key, event) {
    Promise.resolve(this.props.dispatch(setState({ key: key, value: parseInt(event.target.value) })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }

  toggleInput() {
    this.props.dispatch(setState({ key: 'showExtraEmployerRateInput', value: !this.props.terms.showExtraEmployerRateInput }))
  }

  render() {
    return (<div className={`col-xs-12 col-md-4 base-savings drawer-${this.props.drawer}`}>
      <h3>Séreignarsparnaður</h3>

      <label className="simpleLabel" htmlFor="currentlyOwned">Núverandi inneign</label>
      <Cleave placeholder="Núverandi inneign"
        id="currentlyOwned"
        options={{
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        }}
        value={this.props.terms.extraCurrentValue}
        onBlur={this.onInputChange.bind(this, 'extraCurrentValue')} />

      <div className="form-group mb-5">
        <h4>Heildarframlag</h4>
        <div className="contribution-container">
          <div className="contrubution-p-container">
            <p className="contribution">Framlag launagreiðanda: </p>
            {!this.props.terms.showExtraEmployerRateInput ?
              <a className="second-p" href="javascript:void(0)" onClick={() => this.toggleInput()}>{`${formatPercentage(this.props.terms.extraEmployerRate, 1)}%`} <i className="fa fa-pencil-square-o" aria-hidden="true"></i></a>
              :
              [<Cleave key={1} className="small" placeholder="Framlag launagreiðanda"
                options={{
                  numeral: true,
                  numeralDecimalMark: ',',
                  delimiter: '.'
                }}
                value={this.props.terms.extraEmployerRate10}
                onBlur={this.onInputChange.bind(this, 'extraEmployerRate')} />,
                ' ',
              <a key={2} href="javascript:void(0)" onClick={() => this.toggleInput()}>
                <i className="fa fa-floppy-o" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19">
                    <path fill="#585858" fillRule="nonzero" d="M14.3313.0724a.6.6 0 00-.3563.175l-9 9a.6.6 0 00-.1625.3l-.8 3.8a.6.6 0 00.7125.7125l3.8-.8a.6.6 0 00.3-.1687l9-9a.6.6 0 000-.8438l-3-3a.6.6 0 00-.4937-.175zM14.4 1.5162l2.15 2.1562-8.15 8.15-2.15-2.15 8.15-8.1562zM.5375 2.0724a.6.6 0 00-.5375.6v14.8a.6.6 0 00.6.6h14.8a.6.6 0 00.6-.6v-8.8a.6002.6002 0 00-.9025-.5267.6.6 0 00-.2975.5267v8.2H1.2v-13.6h8.2a.6.6 0 100-1.2H.6a.6.6 0 00-.0625 0zm5.2188 8.8L7.1936 12.31l-1.8187.3875.3812-1.825z" />
                  </svg>
                </i>
              </a>
              ]
            }
          </div>
          <div className="contrubution-p-container">
            <p className="contribution">Eigið framlag: </p>
            <p className="second-p"> {`${formatPercentage(this.props.terms.extraEmployeeRate, 1)}%`}</p>
          </div>
        </div>
        <RangeSlider
          id="extraEmployeeRate"
          value={this.props.terms.extraEmployeeRate}
          step={10}
          onChange={this.sliderChange.bind(this, 'extraEmployeeRate')}
          max={40}
          min={20}
          tooltipLabel={function (val) { return `${val / 10.0}%` }}
        />
      </div>

      <div className="form-group single-color-slider">
        <h4>Aldur við byrjun úttektar</h4>
        <RangeSlider
          id="extraAgeOutSlider"
          value={this.props.terms.extraAgeOut}
          onChange={this.sliderChange.bind(this, 'extraAgeOut')}
          step={1}
          min={60}
          max={85}
        />
      </div>

      <div className="form-group single-color-slider">
        <h4>Vænt ávöxtun</h4>
        <RangeSlider
          id="extraReturnRateSlider"
          value={this.props.terms.extraReturnRate}
          onChange={this.sliderChange.bind(this, 'extraReturnRate')}
          step={5}
          min={0}
          max={60}
          tooltipLabel={function (val) { return `${val / 10.0}%` }}
        />
      </div>

      <div className="form-group single-color-slider">
        <h4>Útborgunartími</h4>
        <RangeSlider
          id="extraPayoutYears"
          value={this.props.terms.extraPayoutYears}
          onChange={this.sliderChange.bind(this, 'extraPayoutYears')}
          step={1}
          min={1}
          max={20}
        />
      </div>

    </div>);
  }
}


export default asDrawer(PensionCalculatorExtraSavings);


