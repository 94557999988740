import React from 'react';


function asDrawer(WrappedComponent) {
  class Drawer extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        drawer: props.drawer
      }
    }


    onDrawerToggle() {
      this.setState({
        drawer: this.state.drawer == 'open' ? 'closed' : 'open'
      });
    }

    componentWillReceiveProps(nextProps, nextContext) {
      if (nextProps.drawer !== this.state.drawer) {
        this.setState({drawer: nextProps.drawer })
      }
    }

    render() {
      // Use JSX spread syntax to pass all props and state down automatically.
      return <WrappedComponent {...this.props} {...this.state} onDrawerToggle={this.onDrawerToggle} />;
    }
  }

  Drawer.defaultProps = {
    drawer: 'closed',
  }

  return Drawer;
}

export default asDrawer;
