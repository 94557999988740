import React from 'react';
import { connect } from 'react-redux'
import { setState, overwriteState, fetchPension } from '../../redux/actions';
import { formatISK, formatPercentage, buildParams, deparam, findGetParameter } from './utils';
var Promise = require('es6-promise').Promise;

import Cleave from 'cleave.js/react';

import PensionCalculatorBaseSavings from './pension-calculator-base-savings'
import PensionCalculatorSpecialSavings from './pension-calculator-special-savings'
import PensionCalculatorExtraSavings from './pension-calculator-extra-savings'
import PensionCalculatorResults from './pension-calculator-results'

class PensionCalculator extends React.Component {

  constructor(props) {
    super(props);
    this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onBlurMonthlyPay = this.onBlurMonthlyPay.bind(this);
    this.onBlurAgeIn = this.onBlurAgeIn.bind(this);
  }

  componentDidMount() {
    let queryParams = window.location.search.replace("?", "")
    if (queryParams !== "") {
      let newTerms = deparam(queryParams, true)


      Promise.resolve(delete newTerms.showResults)
        .then(() => {
          Promise.resolve(this.props.dispatch(overwriteState(newTerms)))
            .then(() => {
              Promise.resolve(this.props.dispatch(fetchPension(buildParams(this.props.terms))))
                .then(() => {
                  this.props.dispatch(setState({ key: 'showResults', value: true }))
                })
            })
        })
    }

    else {
      Promise.resolve(this.props.dispatch(setState({ key: 'monthlyPay', value: this.props.monthlyPay })))
        .then(() => {
          Promise.resolve(this.props.dispatch(setState({ key: 'ageIn', value: this.props.ageIn })))
            .then(() => {
              this.props.dispatch(fetchPension(buildParams(this.props.terms)))
            })
        })
    }
  }

  onCheckBoxChange(key, event) {
    Promise.resolve(this.props.dispatch(setState({ key: key, value: event.target.checked })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }

  onInputChange(key, event) {
    var val = event.target.value.replace(/[\.\-]/g, '');
    if (val === "") {
      val = 0;
    }

    Promise.resolve(this.props.dispatch(setState({ key: key, value: parseInt(val) })))
      .then(() => {
        // this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }

  onPercentageInputChange(key, event) {
    var val = event.target.value.replace(/\,/g, '.');

    Promise.resolve(this.props.dispatch(setState({ key: key, value: val / 100 })))
      .then(() => {
        // this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }

  handleClick() {
    Promise.resolve(this.props.dispatch(fetchPension(buildParams(this.props.terms))))
      .then(() => {
        this.props.dispatch(setState({ key: 'showResults', value: true }))
      })
  }

  onKeyUp(key, event) {
    if (event.which == 13 || event.keyCode == 13) {
      var val = event.target.value.replace(/[\.\-]/g, '');
      if (val === "") {
        val = 0;
      }
      Promise.resolve(this.props.dispatch(setState({ key: key, value: parseInt(val) })))
        .then(() => {
          this.props.dispatch(fetchPension(buildParams(this.props.terms)))
        })
    }
  }

  onBlurMonthlyPay(event) {
    var val = event.target.value.replace(/[\.\-]/g, '');
    if (val === "") {
      val = 0;
    }
    Promise.resolve(this.props.dispatch(setState({ key: 'monthlyPay', value: parseInt(val) })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      })
  }

  onBlurAgeIn(event) {
    var val = event.target.value.replace(/[\.\-]/g, '');

    if (val > 67) {
      val = 67
    } if (val < 16) {
      val = 16
    }

    Promise.resolve(this.props.dispatch(setState({ key: 'ageIn', value: val })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      })
  }

  onSelectChange(key, event) {
    var val = event.target.value.replace(/[\.\-]/g, '');
    if (val === "") {
      val = 0;
    }

    Promise.resolve(this.props.dispatch(setState({ key: key, value: val })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }

  render() {

    let testing = findGetParameter("test")

    return (

      <form className="container">
        <div className="row justify-content-center">
          <div className="form-group first-form-group col-md-4 col-sm-12 col-xs-12">

            <label htmlFor="birthYear">Fæðingarár</label>
            <select value={this.props.terms.baseBirthYear} onChange={this.onSelectChange.bind(this, 'baseBirthYear')}>
              <option value="">---</option>
              {Array.from(Array(61).keys()).map(i =>
                <option value={i + 1955}>{i + 1955}</option>
              )}
            </select>

          </div>
          <div className="form-group second-form-group col-md-4 col-sm-12 col-xs-12">
            <label htmlFor="startAge">Aldur</label>
            <Cleave placeholder="Aldur"
              id="startAge"
              options={{
                numeral: true,
                numeralDecimalMark: ',',
                delimiter: ''
              }}
              value={this.props.terms.ageIn}
              onKeyUp={this.onKeyUp.bind(this, 'ageIn')}
              onBlur={this.onBlurAgeIn} />


          </div>
          <div className="form-group third-form-group col-xs-12 col-md-4 col-sm-12">


            <label htmlFor="monthlyPay">Mánaðarlaun</label>
            <Cleave placeholder="Mánaðarlaun"
              id="monthlyPay"
              options={{
                numeral: true,
                numeralDecimalMark: ',',
                delimiter: '.'
              }}
              value={this.props.terms.monthlyPay}
              onKeyUp={this.onKeyUp.bind(this, 'monthlyPay')}
              onBlur={this.onBlurMonthlyPay} />

          </div>
        </div>


        <div className="row justify-content-center checkbox-row">
          <div className="form-group checkbox-col col-xs-12 col-sm-4">
            <div className="checkbox">
              <h3>Reikna út:</h3>
              <label>
                <input type="checkbox" defaultChecked={this.props.terms.baseSavingsChecked} onChange={this.onCheckBoxChange.bind(this, 'baseSavingsChecked')} />
                Skyldusparnað
              </label>
            </div>
          </div>
          <div className="form-group checkbox-col col-xs-12 col-sm-4">
            <div className="checkbox">
              <h3>&nbsp;</h3>
              <label>
                <input type="checkbox" defaultChecked={this.props.terms.specialSavingsChecked} onChange={this.onCheckBoxChange.bind(this, 'specialSavingsChecked')} />
                Tilgreinda séreign
              </label>
            </div>
          </div>
          <div className="form-group checkbox-col col-xs-12 col-sm-4">
            <div className="checkbox">
              <h3>&nbsp;</h3>
              <label>
                <input type="checkbox" defaultChecked={this.props.terms.extraSavingsChecked} onChange={this.onCheckBoxChange.bind(this, 'extraSavingsChecked')} />
                Séreignarsparnað
              </label>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">

          <PensionCalculatorBaseSavings {...this.props} drawer={this.props.terms.baseSavingsChecked ? 'open' : 'closed'} />
          <PensionCalculatorSpecialSavings {...this.props} drawer={this.props.terms.specialSavingsChecked ? 'open' : 'closed'} />
          <PensionCalculatorExtraSavings {...this.props} drawer={this.props.terms.extraSavingsChecked ? 'open' : 'closed'} />

          <div className="checkbox col-md-12 col-sm-12 pension-calculate-btn">
            <a href="#jump-down-pension">
              <input id="jump-down-pension" type="button" className="btn birta-btn pull-right submit" value='Reikna' onClick={this.handleClick} />
            </a>
          </div>

        </div>

        <div className="row">
          <PensionCalculatorResults {...this.props} drawer={this.props.terms.showResults ? 'open' : 'closed'} />
        </div>


      </form >

    )

  }

};

function mapStateToProps(state) {

  var f = { pension: {}, terms: {} }
  f.pension = (state.pension === undefined) ? {} : state.pension
  f.terms = (state.terms === undefined) ? {} : state.terms
  return f;
}

export default connect(mapStateToProps)(PensionCalculator);

