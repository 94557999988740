import { combineReducers } from 'redux'
import moment from 'moment'
import { buildParams, filterParams, findGetParameter } from '../components/pension_calculator/utils';
import $ from 'jquery'

import {
  REQUEST_PENSION,
  RECEIVE_PENSION,
  SET_STATE,
  OVERWRITE_STATE,
} from './actions'

const _defaultPensionState = {
  data: {
    as_payments: {
      employer: [],
      employee: [],
      interests: [],
      years: [],
    }
  }
}

const _defaultTermsState = {
  ageIn: 25,
  monthlyPay: 450000,

  baseAgeOut: 67,
  baseEmployeeRate: 40,
  baseEmployerRate: 80,
  baseReturnRate: 35,
  baseCurrentValue: 0,
  baseSavingsChecked: true,
  baseSliderValue: 120,
  baseBirthYear: null,

  extraAgeOut: 67,
  extraEmployeeRate: 40,
  extraEmployerRate: 20,
  extraEmployerRate10: 2,
  extraReturnRate: 35,
  extraCurrentValue: 0,
  extraPayoutYears: 10,
  extraSavingsChecked: true,

  specialAgeOut: 67,
  specialEmployeeRate: 0,
  specialEmployerRate: 35,
  specialSliderValue: 35,
  specialReturnRate: 35,
  specialCurrentValue: 0,
  specialPayoutYears: 5,
  specialPayoutYearsMinValue: 1,
  specialSavingsChecked: true,

  showResults: false,
  showExtraEmployerRateInput: false,
}


export function pension(state = _defaultPensionState, action) {
  switch (action.type) {
    case REQUEST_PENSION:
      state = {
        ...state,
        isFetching: true,
      }
      return state;
    case RECEIVE_PENSION:
      let data = action.data;
      state = {
        ...state,
        isFetching: false,
        data: data,
      }
      return state;
    default:
      return state;
  }
}


export function terms(state = _defaultTermsState, action) {
  let url = ''
  switch (action.type) {
    case SET_STATE:
      state = { ...state }
      state[action.data.key] = action.data.value;
      url = `${window.location.pathname}?${decodeURIComponent($.param(filterParams(state)))}`
      history.replaceState(null, document.title, url)
      state.url = `https://www.birta.is${url}`
      return state;
    case OVERWRITE_STATE:
      state = Object.assign(state, action.data)
      let testing = findGetParameter("test")
      if (state.baseBirthYear === null && testing) {
        state.baseBirthYear = (new Date()).getFullYear() - state.ageIn
      }
      url = `${window.location.pathname}?${decodeURIComponent($.param(filterParams(state)))}`
      history.replaceState(null, document.title, url)
      state.url = `https://www.birta.is${url}`
      return state
    default:
      return state;
  }
}
