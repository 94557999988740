import React from 'react';
import ReactDOM from 'react-dom';
import asDrawer from './drawer';
import { formatISK, formatPercentage, buildParams } from './utils';
import { setState, fetchPension } from '../../redux/actions';
import Cleave from 'cleave.js/react';

import RangeSlider from 'react-bootstrap-range-slider';

window.ReactDOM = ReactDOM

class PensionCalculatorSpecialSavings extends React.Component {

  constructor(props) {
    super(props);
    this.baseSliderChange = this.baseSliderChange.bind(this);

  }


  onInputChange(key, event) {
    var val = event.target.value.replace(/\./g, '');
    if (val === "") { val = 0 }
    Promise.resolve(this.props.dispatch(setState({ key: key, value: parseInt(val) })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }


  calculateRangeHighlights() {
    return [
      { "start": 0, "end": this.props.terms.specialEmployerRate }
    ];
  }

  baseSliderChange(event) {
    let specialSlider = event.target.value
    let baseSlider = this.props.terms.baseEmployeeRate + this.props.terms.baseEmployerRate + this.props.terms.specialEmployerRate - specialSlider
    Promise.resolve(this.props.dispatch(setState({ key: 'baseSliderValue', value: baseSlider })))
      .then(() => {
        Promise.resolve(this.props.dispatch(setState({ key: 'specialSliderValue', value: specialSlider })))
          .then(() => {
            this.props.dispatch(fetchPension(buildParams(this.props.terms)))
          })
      })

  }

  sliderChange(key, event) {
    if (key === 'specialAgeOut') {
      Promise.resolve(this.props.dispatch(setState({ key: 'specialPayoutYearsMinValue', value: Math.max(1, 67 - parseInt(event.target.value)) })))
        .then(() => {
          if (this.props.terms.specialPayoutYears < this.props.terms.specialPayoutYearsMinValue) {
            this.props.dispatch(setState({ key: 'specialPayoutYears', value: this.props.terms.specialPayoutYearsMinValue }))
          }
        })
    }

    Promise.resolve(this.props.dispatch(setState({ key: key, value: parseInt(event.target.value) })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }

  render() {
    return (<div className={`col-xs-12 col-md-4 base-savings drawer-${this.props.drawer}`}>
      <h3>Tilgreind séreign</h3>

      <label className="simpleLabel" htmlFor="currentlyOwnedSpecial">Núverandi inneign</label>
      <Cleave placeholder="Núverandi inneign"
        id="currentlyOwnedSpecial"
        options={{
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        }}
        value={this.props.terms.specialCurrentValue}
        onBlur={this.onInputChange.bind(this, 'specialCurrentValue')} />

      <div className="form-group mb-5">
        <h4>Heildarframlag <span className="info-bubble" data-toggle="tooltip" data-placement="top" title="Allt að 2% frá 1. júlí 2017 en 3,5% frá 1. júlí 2018">
          <i className="fa fa-info" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <g fill="none" fillRule="evenodd"><circle cx="8" cy="8" r="8" fill="#E5AF23" /><path fill="#252525" fillRule="nonzero" d="M7.175 6.5V12h1.65V6.5h-1.65zm-.088-1.573c0-.55.352-.891.913-.891.55 0 .913.341.913.891 0 .528-.363.88-.913.88-.561 0-.913-.352-.913-.88z" /></g>
            </svg>
          </i>
        </span>
        </h4>
        <div className="contribution-container">
          <div className="contrubution-p-container">
            <p className="contribution">Samtrygging: </p>
            <p className="second-p"> {`${formatPercentage(this.props.terms.baseSliderValue, 1)}%`}</p>
          </div>
          <div className="contrubution-p-container">
            <p className="contribution">Tilgreind séreign:  </p>
            <p className="second-p">{`${formatPercentage(this.props.terms.baseEmployeeRate + this.props.terms.baseEmployerRate + this.props.terms.specialEmployerRate - this.props.terms.baseSliderValue, 1)}%`}</p>
          </div>
        </div>

        <RangeSlider
          id="baseSlider"
          value={this.props.terms.specialSliderValue}
          step={5}
          onChange={this.baseSliderChange}
          max={this.props.terms.specialEmployerRate}
          min={0}
          tooltipLabel={function (val) { return `${val / 10.0}%` }}
        />
      </div>

      <div className="form-group single-color-slider">
        <h4>Aldur við byrjun úttektar</h4>
        <RangeSlider
          id="specialAgeOutSlider"
          value={this.props.terms.specialAgeOut}
          onChange={this.sliderChange.bind(this, 'specialAgeOut')}
          step={1}
          min={62}
          max={80}
        />

      </div>

      <div className="form-group single-color-slider">
        <h4>Vænt ávöxtun</h4>
        <RangeSlider
          id="specialReturnRateSlider"
          value={this.props.terms.specialReturnRate}
          onChange={this.sliderChange.bind(this, 'specialReturnRate')}
          step={5}
          min={0}
          max={60}
          tooltipLabel={function (val) { return `${val / 10.0}%` }}
        />
      </div>

      <div className="form-group single-color-slider">
        <h4>Útborgunartími</h4>
        <RangeSlider
          id="specialPayoutYears"
          value={this.props.terms.specialPayoutYears}
          onChange={this.sliderChange.bind(this, 'specialPayoutYears')}
          step={1}
          min={this.props.terms.specialPayoutYearsMinValue}
          max={20}
        />
        {/* <div className="row">
          <div className="col-xs-6 slide-label">{this.props.terms.specialPayoutYearsMinValue} ár</div>
          <div className="col-xs-6 text-right slide-label">20 ár</div>
        </div> */}
      </div>

    </div>);
  }
}

export default asDrawer(PensionCalculatorSpecialSavings);


