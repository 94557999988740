import React from 'react';
import ReactDOM from 'react-dom';
import asDrawer from './drawer';
import Clipboard from 'clipboard'


window.ReactDOM = ReactDOM

class PensionCopyLink extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        new Clipboard('.clipboard-copy')
    }

    render() {
        return (<div className="copy-link-container">
            <div className="copy-links">
                <button type="button" aria-label="Afrita slóð á niðurstöður" className="btn clipboard-copy" data-clipboard-text={this.props.terms.url} data-toggle={"tooltip"} data-placement={"top"} title={"Afrita slóð á niðurstöður"}>
                </button>
            </div>
    </div>);
  }
}

export default asDrawer(PensionCopyLink);
