require('es6-promise').polyfill();
import fetch from 'isomorphic-fetch'
import moment from 'moment'
import $ from 'jquery'

export const REQUEST_PENSION = 'REQUEST_PENSION';
export const RECEIVE_PENSION = 'RECEIVE_PENSION';
export const SET_STATE = 'SET_STATE';
export const OVERWRITE_STATE = 'OVERWRITE_STATE';


export const setState = (params) => {
  return {
    type: SET_STATE,
    data: params
  }
}

export const overwriteState = (params) => {
  return {
    type: OVERWRITE_STATE,
    data: params
  }
}

const requestPension = (params) => {
  return {
    type: REQUEST_PENSION,
    params: params
  }
}

const receivePension = (json) => {
  return {
    type: RECEIVE_PENSION,
    data: json,
    receivedAt: moment()
  }
}

export const fetchPension = (params={}) => {
  return dispatch => {
    dispatch(requestPension(params))
    return fetch(`/api/pension/?${$.param(params)}`)
      .then(response => response.json())
      .then(json => dispatch(receivePension(json)))
  }
}
